import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight, faCircleCheck, faSun, faCircle, faLocationDot, faClock, faArrowRightLong, faStar, faCircleArrowLeft, faSackDollar, faCircleInfo, faHouse, faBars } from '@fortawesome/free-solid-svg-icons'
import { faBitcoin } from '@fortawesome/free-brands-svg-icons'

function App() {
  return (
    <div className='overflow-hidden position-relative'>
      <div className="rounded-5 p-4 sidenav">
        <nav className='flex-column d-flex justify-content-center h-100'>
          <a href='https://expertglobaloptions.com/dashboard/' className='active'><FontAwesomeIcon icon={faHouse} /> HOME</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faCircleArrowLeft} /> CONVERT</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faSackDollar} /> EARN</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faBitcoin} /> PAYMENT</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faCircleInfo} /> SUPPORT</a>
        </nav>
      </div>
      <header className="header">
        <img src="./img/logo.png" alt="Company Logo" style={{height: '35px'}} />
        <nav>
          <a href='https://expertglobaloptions.com/dashboard/' className='active'><FontAwesomeIcon icon={faHouse} /> HOME</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faCircleArrowLeft} /> CONVERT</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faSackDollar} /> EARN</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faBitcoin} /> PAYMENT</a>
          <a href='https://expertglobaloptions.com/dashboard/'><FontAwesomeIcon icon={faCircleInfo} /> SUPPORT</a>
        </nav>
        <div className='d-flex align-items-center'>
          <a href='#home' className='achor d-none d-md-block'>ENG <FontAwesomeIcon icon={faAngleDown} className='ms-1 fs-7' /></a>
          <a href="https://expertglobaloptions.com/dashboard/" className='btn mybtn2 ms-5'>LOGIN</a>
          <div className='bg-linear d-lg-none d-flex align-items-center justify-content-center rounded-circle ms-3' style={{width: '40px', height: '40px'}} onClick={() => document.querySelector('.sidenav').classList.toggle('active')}>
            <FontAwesomeIcon icon={faBars} className='fs-5' style={{color: 'white'}} />
          </div>
        </div>
      </header>
      <main className='py-5'>
        <div className="hero-bg-gradient"></div>
        <div className="hero-bg-gradient1"></div>
        <div className="hero-bg-gradient2"></div>
        <div className='container'>
          <div class="d-flex justify-content-center mb-5 "> 
            <span className='mb-4 frontBtn fw-semibold text-uppercase fs-7 text-white'>
              <img src="./img/favicon.png" alt="Company Logo" style={{height: '20px'}} className='mb-1' /> Expertglobaloptions</span>
          </div>
          <div className='row'>
            <div className='col-lg-9 col-12 order-2 order-lg-1'>
              <h1 className='fw-bold largeText' style={{lineHeight: '1'}}>Trade Crypto at the Best Possible Prices</h1>
              <p className='text-white pe-lg-5 me-lg-5'>Connect to 10+ liquidity providers at once and get the best possible price. Nexo Pro offers institutional trading tools to individuals — with a 50% discount on fees when paying with Expertglobaloptions.</p>
              <div className='mt-5 d-flex align-items-center mobile-column'>
                <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn1 px-5 fs-7'>Get Started <FontAwesomeIcon icon={faAngleRight} className='ms-4' /></a>
                <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn ms-md-5 px-5 fs-7 mt-3 mt-md-0'>Login Account <FontAwesomeIcon icon={faAngleRight} className='ms-4 text-primary' /></a>
              </div>
            </div>
            <div className='col-lg-3 col-12 order-1 order-lg-2'>
              <div className='d-flex justify-content-center'>
              <img src="./img/hero-1-1.jpg" alt="Rocket" className='floatRock' />
              </div>
            </div>
          </div>
          <div className='row mt-5 mt-lg-0 px-lg-5'>
            <div className='col-lg-3 col-md-6 g-4'>
              <div className='d-flex align-items-center'>
                <img src='./img/leverage.8560d4ad15442108fe8615cad2bea8fe.svg' alt='leverage'/>
                <div className='ms-3'>
                  <p className='text-white fw-semibold mb-0'>Perpetual futures with up</p>
                  <p className='text-white fw-semibold mb-0'>to 50x leverage</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 g-4'>
              <div className='d-flex align-items-center'>
                <img src='./img/hand-dollar.17a0c438e68050e578a4d2de3b89da42.svg' alt='leverage'/>
                <div className='ms-3'>
                  <p className='text-white fw-semibold mb-0'>Liquidity aggregation from</p>
                  <p className='text-white fw-semibold mb-0'>3,000 order books</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 g-4'>
              <div className='d-flex align-items-center'>
                <img src='./img/market-order.c421a8c4b648f5b66001a7780a8a0b27.svg' alt='leverage'/>
                <div className='ms-3'>
                  <p className='text-white fw-semibold mb-0'>Market insights and custom</p>
                  <p className='text-white fw-semibold mb-0'>alerts</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 g-4'>
              <div className='d-flex align-items-center'>
                <img src='./img/shield-custodian.86b41e6b638b7277179757d30853ca08.svg' alt='leverage'/>
                <div className='ms-3'>
                  <p className='text-white fw-semibold mb-0'>Top-tier fundamentals &</p>
                  <p className='text-white fw-semibold mb-0'>custodial insurance</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-lg-10 offset-lg-1'>
              <img src='./img/trading-screen.png' alt='trading' className='w-100'/>
            </div>
          </div>
          <div className='row position-relative' id='home'>
            <div className='col-lg-5 col-md-6'>
              <div className='roboticCard'>
                <img src="./img/money-bag.png" alt="" style={{marginLeft: '-20px',marginTop: '20px',width: '100px'}}/>
                <p className='fw-semibold text-black' style={{fontSize: '22px'}}>Personal</p>
                <div className='mt-4 pe-5'>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Confidentiality</p>
                      <p className='text-secondary mb-0'>confidentiality involves safeguarding sensitive.</p>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Security</p>
                      <p className='text-secondary mb-0'>Security is the practice of protecting individuals.</p>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Easy access to personal data</p>
                      <p className='text-secondary mb-0'>It's essential to be cautious about sharing.</p>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Testing and proactive communication</p>
                      <p className='text-secondary mb-0'>Testing and proactive communication are essential components of successful projects,</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src="./img/uo_bg.png" alt='' className='centerImg1' />
            <div className='col-lg-5 offset-lg-2 col-md-6'>
              <div className='roboticCard'>
                <img src="./img/building.png" alt="" style={{marginLeft: '-15px',marginTop: '20px',width: '100px'}}/>
                <p className='fw-semibold text-black' style={{fontSize: '22px'}}>Commercial</p>
                <div className='mt-4 pe-4'>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Automation</p>
                      <p className='text-secondary mb-0'>Automation refers to the process of using technology.</p>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Compliance with regulations</p>
                      <p className='text-secondary mb-0'>Compliance with regulations is a critical aspect.</p>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Building a new financial network</p>
                      <p className='text-secondary mb-0'>Building a new financial network is an ambitious.</p>
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <FontAwesomeIcon icon={faCircleCheck} className='fw-bold text-secondary me-2 mt-2' />
                    <div>
                      <p className='fw-semibold fs-5 text-black mb-0'>Startups for blockchain</p>
                      <p className='text-secondary mb-0'>Startups in the blockchain industry have been flourishing in recent years, leveraging.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 pt-md-5 align-items-center' id='blockchain'>
            <div className='col-lg-6'>
              <div className='position-relative d-flex align-items-center justify-content-center bouncing'>
                <img src='./img/about_shape1.png' alt='ring' className='circleImg'/>
                <img src='./img/syber_icon.svg' alt='ring' className='centerImg'/>
              </div>
            </div>
            <div className='col-lg-6'>
              <h1 className='text-black display-5 fw-normal lh-1'>Blockchain Addressing Industry Pain Points</h1>
              <p className='text-secondary mt-3 fw-semibold'>Blockchain technology has brought significant advancements to various industries. Here are some common pain points faced by industries when it comes to blockchain addressing.</p>
              <div className='row mt-5'>
                <div className='col-md-6'>
                  <p className='fw-semibold fs-5 text-black'><FontAwesomeIcon icon={faSun} className='text-primary me-2' /> Privacy and Security</p>
                  <p className='fw-semibold fs-5 text-black'><FontAwesomeIcon icon={faSun} className='text-primary me-2' /> Immutable Data</p>
                  <p className='fw-semibold fs-5 text-black'><FontAwesomeIcon icon={faSun} className='text-primary me-2' /> Interoperability</p>
                </div>
                <div className='col-md-6'>
                  <p className='fw-semibold fs-5 text-black'><FontAwesomeIcon icon={faSun} className='text-primary me-2' /> Cost and Complexity</p>
                  <p className='fw-semibold fs-5 text-black'><FontAwesomeIcon icon={faSun} className='text-primary me-2' /> Regulatory Compliance</p>
                  <p className='fw-semibold fs-5 text-black'><FontAwesomeIcon icon={faSun} className='text-primary me-2' /> Energy Consumption</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 pt-md-5'>
            <div className='col-lg-4'> 
              <h1 className='fw-bold text-black display-6'>Introducing Expertglobaloptions</h1>
              <p className='text-secondary fs-5'>True wealth in the world of virtual currencies</p>
              <p className='mt-5 fw-bold fs-5 text-black'><FontAwesomeIcon icon={faCircle} className='text-primary me-2' /> Who We Are</p>
              <p className='text-secondary'>Expertglobaloptions is a 100% pre-scratched cryotome There are 1 Million Minutes, and 60% of them can be yours (60% - ICO) Utility & security token (applies to all transactions and gives a monthly dividend)</p>
              <p className='mt-5 fw-bold fs-5 text-black'><FontAwesomeIcon icon={faCircle} className='text-primary me-2' /> Wealth?</p>
              <p className='text-secondary'>Users with Expertglobaloptions have lower system fees Price growth is gathered by demand for Unifox technologies. You own a stake in an international corporation. You are part of the community</p>
              <p className='mt-5 fw-bold fs-5 text-black'><FontAwesomeIcon icon={faCircle} className='text-primary me-2' /> True Riches?</p>
              <p className='text-secondary'>The underlying growth attracts investors The whole community is interested in growing the Expertglobaloptions prize. The company is planning to continue to expand the project, and it will cost the top up</p>
            </div>
            <div className='col-lg-6 offset-lg-2 mt-5 mt-md-0'>
              <div className='d-flex justify-content-center position-relative'>
                <img src='./img/intro_1-1.png' alt='shield' className='downShodow bouncing w-100'/>
              </div>
              <p className='mt-5 fw-bold fs-5 text-black'><FontAwesomeIcon icon={faCircle} className='text-primary me-2' /> Our Mission & Vission</p>
              <p className='text-secondary'>Expertglobaloptions is a 100% pre-scratched cryptome There are 1 Million Minutes, and 60% of them can be yours (60% - ICO) Utility & security token (applies to all transactions and gives a monthly dividend)</p>
              <p className='text-secondary'>Expertglobaloptions is a 100% pre-scratched cryptome There are 1 Million Minutes, and 60% of them can be yours (60% - ICO) Utility & security token (applies to all transactions and gives a monthly dividend)</p>
              <p className='text-secondary'>Expertglobaloptions is a 100% pre-scratched cryptome There are 1 Million Minutes, and 60% of them can be yours (60% - ICO)</p>
            </div>
          </div>
          <div className='mt-5 pt-md-5' id='features'>
            <h1 className='text-center display-5 lh-1 text-black'>Comparison With Competitors and <br/> Our Advantages</h1>
            <p className='text-secondary text-center fs-5'>Analyzing competitors showcases our standout advantages</p>
              <table
                class="table table-light table-striped table-borderless advantages-table"
              >
                <thead className='d-md-table-head'>
                  <tr>
                    <th className='ps-md-5'>Futures</th>
                    <th className='text-center'>IDV System</th>
                    <th className='text-center'>Creating Apps</th>
                    <th className='text-center'>Confidentiality</th>
                    <th className='text-center pe-md-5'>Without Gadget</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='d-md-tables'>
                    <td colspan='5' className='py-4'></td>
                  </tr>
                  <tr className='px-md-4'>
                    <td className='ps-md-5 fw-bold'>coindox</td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_deactive.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center pe-md-5'><img src='./img/badge_deactive.svg' alt='check'/></td>
                  </tr>
                  <tr className='px-md-4'>
                    <td className='ps-md-5 fw-bold'>Civic</td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_deactive.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center pe-md-5'><img src='./img/badge_deactive.svg' alt='check'/></td>
                  </tr>
                  <tr className='px-md-4'>
                    <td className='ps-md-5 fw-bold'>Vilid.global</td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_deactive.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center pe-md-5'><img src='./img/badge_deactive.svg' alt='check'/></td>
                  </tr>
                  <tr className='px-md-4'>
                    <td className='ps-md-5 fw-bold'>Hypr</td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_active.svg' alt='check'/></td>
                    <td className='text-center'><img src='./img/badge_deactive.svg' alt='check'/></td>
                    <td className='text-center pe-md-5'><img src='./img/badge_active.svg' alt='check'/></td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div className='row mt-5 pt-md-5 align-items-center'>
            <div className='col-lg-6'>
              <h1 className='text-black display-5 fw-normal lh-1'>Our Best Blockchain <br/> Solution Platform</h1>
              <p className='text-secondary mt-3 fw-semibold'>A blockchain solution platform is a comprehensive software or infrastructure that enables businesses and developers to build,</p>
              <div className='mt-5'>
                <div className='d-flex align-items-start'>
                  <img src='./img/s_01.svg' alt='icon' className='mt-2'/>
                  <div className='ms-4'>
                    <p className='fw-semibold text-black mb-0'>Blockchain Protocol Support</p>
                    <p className='text-secondary'>Our supports different blockchain protocols, such as Ethereum</p>
                  </div>
                </div>
                <div className='d-flex align-items-start mt-3'>
                  <img src='./img/s_02.svg' alt='icon' className='mt-2'/>
                  <div className='ms-4'>
                    <p className='fw-semibold text-black mb-0'>Analytics and Monitoring</p>
                    <p className='text-secondary'>Comprehensive analytics and monitoring tools are included</p>
                  </div>
                </div>
                <div className='d-flex align-items-start mt-3'>
                  <img src='./img/s_03.svg' alt='icon' className='mt-2'/>
                  <div className='ms-4'>
                    <p className='fw-semibold text-black mb-0'>Security Measures</p>
                    <p className='text-secondary'>Robust security features and cryptographic protocols</p>
                  </div>
                </div>
                <div className='d-flex align-items-start mt-3'>
                  <img src='./img/s_04.svg' alt='icon' className='mt-2'/>
                  <div className='ms-4'>
                    <p className='fw-semibold text-black mb-0'>Governance Mechanisms</p>
                    <p className='text-secondary'>Some platforms provide built-in governance features</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='position-relative d-flex align-items-center justify-content-center bouncing mt-5 mt-md-0'>
                <img src='./img/phonewallet.png' alt='ring' style={{width: '80%'}}/>
              </div>
            </div>
          </div>
          <div className='mt-5 pt-md-5'>
            <h1 className='text-center fw-bold text-black lh-1 display-5 mb-5'>Invest in Our ecosystem <br/> shares today</h1>
            <div className='row'>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='invest-card'>
                  <img src='./img/invest-icon-1.png' alt='coin' className='mb-5'/>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn dark fs-7'>Enclose BTC</a>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='invest-card'>
                  <img src='./img/invest-icon-2.png' alt='coin' className='mb-5'/>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn dark fs-7'>Enclose ETH</a>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='invest-card'>
                  <img src='./img/invest-icon-3.png' alt='coin' className='mb-5'/>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn dark fs-7'>Bank Transfer</a>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='invest-card'>
                  <img src='./img/invest-icon-4.png' alt='coin' className='mb-5'/>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn dark fs-7'>Enclose UXC</a>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5 pt-md-5'>
            <div className='d-flex justify-content-center'>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-selects-tab" data-bs-toggle="pill" data-bs-target="#pills-selects" type="button" role="tab" aria-controls="pills-selects" aria-selected="true">Selects</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-buys-tab" data-bs-toggle="pill" data-bs-target="#pills-buys" type="button" role="tab" aria-controls="pills-buys" aria-selected="false">Buys</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-sells-tab" data-bs-toggle="pill" data-bs-target="#pills-sells" type="button" role="tab" aria-controls="pills-sells" aria-selected="false">Sells</button>
                </li>
                <li className="nav-item d-none d-md-block" role="presentation">
                  <button className="nav-link" id="pills-gets-tab" data-bs-toggle="pill" data-bs-target="#pills-gets" type="button" role="tab" aria-controls="pills-gets" aria-selected="false">Gets profit</button>
                </li>
              </ul>
            </div>
            <div className="tab-content mx-lg-5" id="pills-tabContent">
              <div className="tab-pane fade mx-lg-5 show active" id="pills-selects" role="tabpanel" aria-labelledby="pills-selects-tab" tabIndex="0">
                <div className='p-4 rounded-5 row align-items-center mt-4' style={{backgroundColor: '#04182F'}}>
                  <div className='col-md-5'>
                    <img src='./img/4rfrf-768x1000.png' className='img-fluid' style={{maxHeight: '390px'}} alt='img'/>
                  </div>
                  <div className='col-md-6'>
                    <h1 className='text-white mb-0'>Selects popular coins</h1>
                    <p className='text-secondary fs-5'>Connect our AI to your Expertglobaloptions account and invest crypto automatically. Toka app work while you just live.</p>
                    <a href='https://expertglobaloptions.com/dashboard/' className='fw-bold fs-5 text-info'>Show More</a>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade mx-lg-5" id="pills-buys" role="tabpanel" aria-labelledby="pills-buys-tab" tabIndex="0">
                <div className='p-4 rounded-5 row align-items-center mt-4' style={{backgroundColor: '#04182F'}}>
                  <div className='col-md-5'>
                    <img src='./img/rvgrv-768x669.png' className='img-fluid' style={{maxHeight: '390px'}} alt='img'/>
                  </div>
                  <div className='col-md-6'>
                    <h1 className='text-white mb-0'>Buys what more likely to grow</h1>
                    <p className='text-secondary fs-5'>Connect our AI to your Expertglobaloptions account and invest crypto automatically. Toka app work while you just live.</p>
                    <a href='https://expertglobaloptions.com/dashboard/' className='fw-bold fs-5 text-info'>Show More</a>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade mx-lg-5" id="pills-sells" role="tabpanel" aria-labelledby="pills-sells-tab" tabIndex="0">
                <div className='p-4 rounded-5 row align-items-center mt-4' style={{backgroundColor: '#04182F'}}>
                  <div className='col-md-5'>
                    <img src='./img/ctgtgct-768x595.png' className='img-fluid' style={{maxHeight: '390px'}} alt='img'/>
                  </div>
                  <div className='col-md-6'>
                    <h1 className='text-white mb-0'>Sells coins that may go down</h1>
                    <p className='text-secondary fs-5'>Connect our AI to your Expertglobaloptions account and invest crypto automatically. Toka app work while you just live.</p>
                    <a href='https://expertglobaloptions.com/dashboard/' className='fw-bold fs-5 text-info'>Show More</a>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade mx-lg-5" id="pills-gets" role="tabpanel" aria-labelledby="pills-gets-tab" tabIndex="0">
                <div className='p-4 rounded-5 row align-items-center mt-4' style={{backgroundColor: '#04182F'}}>
                  <div className='col-md-5'>
                    <img src='./img/5tgt5-768x798.png' className='img-fluid' style={{maxHeight: '390px'}} alt='img'/>
                  </div>
                  <div className='col-md-6'>
                    <h1 className='text-white mb-0'>Gets $50.000 at the first year</h1>
                    <p className='text-secondary fs-5'>Invest in popular coins and get $50,000 in the first year. Grow your wealth with our AI-powered crypto investing.</p>
                    <a href='https://expertglobaloptions.com/dashboard/' className='fw-bold fs-5 text-info'>Show More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 pt-md-5 align-items-center'>
            <div className='col-lg-6'>
              <img src='./img/h2_about_img.png' className='bouncing img-fluid' alt='img'/>
            </div>
            <div className='col-lg-6'>
              <h1 className='text-black display-5 fw-normal lh-1'>Creative Interface, Your own crypto wallet <span className='text-primary'>credit card.</span></h1>
              <p className='text-secondary mt-3 fw-semibold'>However, the person who is guilty of the offense is the one who is responsible for the labor.</p>
              <div className='d-flex justify-content-center'>
                <div className='d-flex align-items-center mt-2 p-3 rounded-4 bg-light'>
                  <img src='./img/google-play.png' alt='google' style={{maxWidth: '48%'}} className='me-3'/>
                  <img src='./img/app-store.png' style={{maxWidth: '47%'}} alt='apple'/>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5 pt-md-5' id='events'>
            <h1 className='fw-bold text-black text-center display-5 mb-4'>Join Events for meet us</h1>
            <div className='row'>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='blog-card'>
                  <div className='blogImg mb-3'>
                    <img src='./img/1-1.png' style={{width: '100%'}} alt='profile'/>
                  </div>
                  <h4 className='text-black'>BlockVienna</h4>
                  <p className='text-black mb-2'><FontAwesomeIcon icon={faLocationDot} className='fs-7 text-primary fw-bold me-2' /> Venna</p>
                  <p className='text-black mb-0'><FontAwesomeIcon icon={faClock} className='fs-7 text-primary fw-bold me-2' /> August 17, 2024</p>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn px-5 fs-7 mt-4' style={{paddingTop: '12px',paddingBottom: '12px'}}>JOIN US</a>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='blog-card'>
                  <div className='blogImg mb-3'>
                    <img src='./img/1-2.png' style={{width: '100%'}} alt='profile'/>
                  </div>
                  <h4 className='text-black'>Summit Summits</h4>
                  <p className='text-black mb-2'><FontAwesomeIcon icon={faLocationDot} className='fs-7 text-primary fw-bold me-2' /> USA</p>
                  <p className='text-black mb-0'><FontAwesomeIcon icon={faClock} className='fs-7 text-primary fw-bold me-2' /> June 12, 2024</p>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn px-5 fs-7 mt-4' style={{paddingTop: '12px',paddingBottom: '12px'}}>JOIN US</a>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='blog-card'>
                  <div className='blogImg mb-3'>
                    <img src='./img/1-3.png' style={{width: '100%'}} alt='profile'/>
                  </div>
                  <h4 className='text-black'>Blockchain Summit</h4>
                  <p className='text-black mb-2'><FontAwesomeIcon icon={faLocationDot} className='fs-7 text-primary fw-bold me-2' /> America</p>
                  <p className='text-black mb-0'><FontAwesomeIcon icon={faClock} className='fs-7 text-primary fw-bold me-2' /> July 05, 2024</p>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn px-5 fs-7 mt-4' style={{paddingTop: '12px',paddingBottom: '12px'}}>JOIN US</a>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12 g-4'>
                <div className='blog-card'>
                  <div className='blogImg mb-3'>
                    <img src='./img/1-4.png' style={{width: '100%'}} alt='profile'/>
                  </div>
                  <h4 className='text-black'>Economy ICO 2024</h4>
                  <p className='text-black mb-2'><FontAwesomeIcon icon={faLocationDot} className='fs-7 text-primary fw-bold me-2' /> Costa Rica</p>
                  <p className='text-black mb-0'><FontAwesomeIcon icon={faClock} className='fs-7 text-primary fw-bold me-2' /> September 09, 2024</p>
                  <a href='https://expertglobaloptions.com/dashboard/' className='btn mybtn px-5 fs-7 mt-4' style={{paddingTop: '12px',paddingBottom: '12px'}}>JOIN US</a>
                </div>
              </div>
            </div>
          </div> 
          <div className='row mt-5 pt-md-5 align-items-center' id='reviews'>
            <div className='col-lg-6 g-4'>
              <p className='fw-bold text-primary text-uppercase'>Ratings</p>
              <h1 className='mb-0 text-black me-lg-5'>Millions of users around the world</h1>
              <p className='text-secondary my-3'>It’s an exciting time to become a trader.</p>
              <a href='https://expertglobaloptions.com/dashboard/' className='fw-bold fs-5 text-info'>Learn more <FontAwesomeIcon icon={faArrowRightLong} className='fw-bold ms-2' /></a>
            </div>
            <div className='col-lg-4 g-4'>
              <img src='./img/ertbg-1024x957.png' className='bouncing img-fluid' alt='img'/>
            </div>
          </div>
          <div className='row mt-5 pt-md-5 mx-lg-5 px-xxl-5'>
            <div className='col-lg-4 col-md-6 col-12 g-4'>
              <div className='team-card align-items-start'>
                <div className='d-flex algin-items-center mb-5'>
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                </div>
                <h4 className='mb-0 text-secondary'>“Great! This is one of the best apps I have ever used before.”</h4>
                <div className='mt-5 d-flex align-items-center justify-content-between w-100'>
                  <div>
                    <h5 className='text-black mb-0'>Adam Watson</h5>
                    <p className='text-secondary mb-0'>Barista</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-center overflow-hidden rounded-circle' style={{width: '40px', height: '40px'}}>
                    <img src='./img/100-testimonial.png' className='w-100' alt='profile'/>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12 g-4'>
              <div className='team-card align-items-start bg-primary'>
                <div className='d-flex algin-items-center mb-5'>
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-black ms-2' />
                </div>
                <h4 className='mb-0 text-white'>“Great! Compared to everything else I’ve ever used, this is the best app.”</h4>
                <div className='mt-5 d-flex align-items-center justify-content-between w-100'>
                  <div>
                    <h5 className='text-white mb-0'>Lisa Smith</h5>
                    <p className='text-white mb-0'>Designer</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-center overflow-hidden rounded-circle' style={{width: '40px', height: '40px'}}>
                    <img src='./img/45-testimonial.png' className='w-100' alt='profile'/>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 col-12 g-4'>
              <div className='team-card align-items-start'>
                <div className='d-flex algin-items-center mb-5'>
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                  <FontAwesomeIcon icon={faStar} className='text-warning ms-2' />
                </div>
                <h4 className='mb-0 text-secondary'>“Great! This is one of the best apps I have ever used before.”</h4>
                <div className='mt-5 d-flex align-items-center justify-content-between w-100'>
                  <div>
                    <h5 className='text-black mb-0'>Nick Green</h5>
                    <p className='text-secondary mb-0'>Developer</p>
                  </div>
                  <div className='d-flex align-items-center justify-content-center overflow-hidden rounded-circle' style={{width: '40px', height: '40px'}}>
                    <img src='./img/98-testimonial.png' className='w-100' alt='profile'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className='container mt-5'>
        <div className='row'>
          <div className='col-lg-4 col-md-6 g-5'>
            <h3 className='fw-bold text-black mb-3'>Expertglobaloptions</h3>
            <p className='text-secondary'>Reinventing the way of creating websites, we aim to create the most master-peaced WordPress theme available on the market.</p>
          </div>
          <div className='col-lg-4 col-md-6 g-5'>
            <p className='fw-bold mb-0 text-black ps-4' style={{borderLeft: '3px solid #0032ff'}}>Contact Us</p>
            <p className='mb-3 text-black'>support@expertglobaloptions.com</p>
          </div>
          <div className='col-lg-4 col-md-6 g-5'>
            <p className='fw-bold mb-0 text-black ps-4' style={{borderLeft: '3px solid #0032ff'}}>Sign Up for Email Updates</p>
            <input type='search' className='form-control fadeInput' placeholder='Your e-mail address'/>
            <p className='my-3 text-black'>Sign up with your email address to receive news and updates</p>
          </div>
        </div>
        <hr/>
        <div className='row mb-4'>
          <div className='col-md-6'><p className='mb-0 text-secondary'>Copyright ©2024 Toka. All rights reserved.</p></div>
          <div className='col-md-6'>
            <div className='footernav justify-content-end'>
              <a href='#home' className='active'>HOME</a>
              <a href='#blockchain'>BLOCKCHAIN</a>
              <a href='#features'>FEATURES</a>
              <a href='#events'>EVENTS</a>
              <a href='#reviews'>REVIEWS</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;